import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import CryptoJS from "crypto-js";
import { CommunityFeature } from "../../../api/Association/AssociationTypes";

class BoardMemberApplicationStore {
    private _api: ApiStore;
    private communityFeature?: CommunityFeature;
    private pageToken?: string;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }


    public async getIframeLink(): Promise<string | null> {
        const url = `https://www.associatedasset.com/board-member-application/`
        return url;
    }
}
export default BoardMemberApplicationStore